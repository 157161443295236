<template>
  <div>
    <a-card title="查询区域" style="margin-bottom: 15px;">
      <a slot="extra" @click="add"
        ><a-button type="primary">网格新增</a-button></a
      >
      <a slot="extra" @click="expor"
        ><a-button
          style="
            background-color: #67c23a;
            color: #f6ffed;
            margin-left: 10px;
            margin-right: 10px;
          "
          >导出</a-button
        ></a
      ><a slot="extra">
        <a-button type="primary" style="margin-right: 10px" @click="search"
          >查询</a-button
        ></a
      >
      <a slot="extra"
        ><a-button type="primary" @click="reset">重置</a-button></a
      >
      <!--  -->
      <ax-form ref="form" :formBuilder="formBuilder" />
    </a-card>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
      :row-key="(record, index) => record.id"
      :indentSize="50"
      size="middle"
      :scroll="{ x: '100%' }"
    >
      <div slot="pname" slot-scope="text, record">
        {{ record.pname ? record.pname : "--" }}
      </div>

      <div slot="supervisorName" slot-scope="text, record">
        {{ record.supervisorName ? record.supervisorName : "--" }}
      </div>
      <div slot="gridWorkerName" slot-scope="text, record">
        {{ record.gridWorkerName ? record.gridWorkerName : "--" }}
      </div>
      <div slot="buildingCount" slot-scope="text, record">
        {{ record.buildingCount ? record.buildingCount : "--" }}
      </div>
      <div slot="address" slot-scope="text, record">
        {{ record.address ? record.address : "--" }}
      </div>
      <div slot="isDraw" slot-scope="text, record">
        <div class="plotting" v-if="record.isDraw === '1'">
          <div style="background-color: #f6ffed; color: #67c23a">已标绘</div>
        </div>
        <div class="plotting" v-else>
          <div style="background-color: #e6e9ee">未标绘</div>
        </div>
      </div>
      <div slot="actions" slot-scope="text, record" class="actions">
        <div class="edit" @click="detailGrid(record)">编辑</div>
        <a-popconfirm
          title="是否确定？"
          ok-text="是"
          cancel-text="否"
          @confirm="confirm(record)"
        >
          <div class="delete">删除</div>
        </a-popconfirm>
      </div>
    </a-table>
    <!-- 地图弹窗 -->
    <el-dialog
      title="网格标绘"
      :visible.sync="visible"
      width="50%"
      :z-index="99"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <mapModal ref="mapDraw"></mapModal>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="mapSave"
          style="background-color: #2A5CFF"
          >保 存</el-button
        >
        <el-button @click="mapCancel">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 标点弹窗 -->
    <el-dialog
      title="地图标点"
      :visible.sync="visiblePoint"
      width="50%"
      :z-index="99"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <mapPoint ref="mapPoint"></mapPoint>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="pointSave"
          style="background-color: #2A5CFF"
          >保 存</el-button
        >
        <el-button @click="pointCancel">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="titles"
      :visible.sync="visibles"
      width="30%"
      :z-index="98"
      :close-on-click-modal="false"
    >
      <addForm
        @openMap="openMap"
        @openPoint="openPoint"
        @retValue="retValue"
        @retId="retId"
        @retPoint="retPoint"
        ref="addform"
      ></addForm>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="formSave"
          :loading="saveType"
          style="background-color: #2A5CFF"
          >保 存</el-button
        >
        <el-button @click="formCancel">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mapModal from "./components/mapModal.vue";
import addForm from "./components/addForm.vue";
import mapPoint from "./components/mapPoint.vue";
import api from "./api";
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "keyword",
    options: { placeholder: "请输入网格名称/网格员" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "网格负责人",
    type: "select",
    model: "gridWorkerId",
    options: {},
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  name: "loginLog",
  components: { mapModal, addForm, mapPoint },
  data() {
    return {
      api,
      searchForm,
      columns: [
        {
          title: "网格(小区)名称",
          dataIndex: "name",
          ellipsis: true,
          width: 260,
          align: "center",
        },
        {
          title: "上级网格",
          dataIndex: "pname",
          ellipsis: false,
          width: 150,
          align: "center",
          scopedSlots: { customRender: "pname" },
        },
        {
          title: "详细地址",
          dataIndex: "address",
          ellipsis: false,
          align: "center",
          width: 240,
          scopedSlots: { customRender: "address" },
        },
        {
          title: "网格负责人",
          dataIndex: "supervisorName",
          ellipsis: false,
          width: 130,
          align: "center",
          scopedSlots: { customRender: "supervisorName" },
        },
        {
          title: "网格员",
          dataIndex: "gridWorkerName",
          ellipsis: false,
          width: 130,
          align: "center",
          scopedSlots: { customRender: "gridWorkerName" },
        },
        {
          title: "楼栋数",
          dataIndex: "buildingCount",
          ellipsis: false,
          width: 80,
          align: "center",
          scopedSlots: { customRender: "buildingCount" },
        },
        {
          title: "地图标绘",
          dataIndex: "isDraw",
          ellipsis: false,
          align: "center",
          width: 200,
          scopedSlots: { customRender: "isDraw" },
        },
        {
          title: "创建日期",
          dataIndex: "createTime",
          ellipsis: true,
          align: "center",
          width: 180,
        },
        {
          title: "操作",
          fixed:'right',
          dataIndex: "actions",
          align: "center",
          width: 180,
          scopedSlots: { customRender: "actions" },
        },
      ],
      searchActions: [
        { name: "add", text: "网格新增", type: "#2A5CFF" },
        { name: "export", text: "导出", type: "#67c23a" },
        { name: "search", text: "查询", type: "#2A5CFF" },
        { name: "reset", text: "重置", type: "#2A5CFF" },
      ],
      formBuilder: this.$common.initGridFormData(
        searchForm,
        { layout: "horizontal", labelWidth: 90 },
        { col: { span: 8 } }
      ),

      // 弹窗相关
      visible: false,
      formValue: null,
      mapValue: null,
      visibles: false,
      formId: "",
      titles: "",
      retIds: "",
      data: [],
      saveType: false,
      visiblePoint: false,
      pointForm: {
        Lng: "",
        Lat: "",
      },
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
  },
  mounted() {
    this.supervisorList();
    this.getList();
  },
  methods: {
    async getList(value) {
      const res = await api.list(value);
      this.data = res.data;
    },

    onShowSizeChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getList(this.pagination.current); //分页查
    },
    // 查询
    search() {
      this.$refs.form.form.validateFields(async (err, value) => {
        this.getList(value);
      });
    },
    reset() {
      this.$refs.form.resetFields();
      this.getList();
    },
    // 删除
    confirm(record) {
      this.popConfirm(record);
    },
    // 点击删除操作的api
    async popConfirm(record) {
      // console.log(record);
      const res = await api.deletegrid(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.getList();
    },
    // 编辑
    detailGrid(record) {
      this.formId = record.id;
      this.titles = "编辑网格";
      this.visibles = true;
      this.$nextTick(async () => {
        // 表单详情
        this.$refs.addform.getgrid(record.id);
        // 地图详情
        const res = await api.getAtlas(record.id);
        if (res.data === "未标绘") {
          this.mapValue = null;
        } else {
          this.mapValue = res.data;
        }
        // console.log(this.mapValue, "编辑");
      });
      if (this.$refs.mapDraw) {
        this.$refs.mapDraw.deletes();
      }
    },
    expor() {
      api.export({
        fileName: "网格管理.xls",
        params: "",
      });
    },
    // 打开弹窗
    add() {
      this.visibles = true;
      console.log(this.pointForm);

      this.titles = "新增网格";
      this.$nextTick(() => {
        this.$refs.addform.getPoint({ Lng: "", Lat: "" });
        this.$refs.addform.getgridTree();
      });
      if (this.$refs.mapDraw) {
        this.$refs.mapDraw.deletes();
      }
    },
    openMap() {
      this.visible = true;
      if (this.titles === "编辑网格" && this.formId) {
        this.$nextTick(() => {
          this.$refs.mapDraw.getgrid(this.formId);
        });
      } else if (this.titles === "新增网格") {
        // this.$refs.mapDraw.initAMap()
      }
    },
    // 地图提交
    mapSave() {
      // this.$refs.mapDraw.unloadMap();
      this.visible = false;
      this.mapValue = this.$refs.mapDraw.getMaparr();
      if (this.$refs.mapDraw.retMaplist().length) {
        let outputArr = this.$refs.mapDraw.retMaplist().map((points) => {
          return {
            colour: "#a5d2ed",
            points: points.map((point) => {
              return { longitude: point[0], latitude: point[1] };
            }),
          };
        });
        outputArr.forEach((item) => {
          this.mapValue.push(item);
        });
      }
      // this.$refs.mapDraw.deletes()
    },
    mapCancel() {
      this.$refs.mapDraw.processdata();
      // this.mapValue = this.$refs.mapDraw.getMaparr();
      this.visible = false;
    },
    retId(e) {
      this.retIds = e;
    },
    retPoint(e) {
      this.pointForm = e;
    },
    // 表单提交
    async formSave() {
      this.formValue = null;
      this.$refs.addform.retList();
      if (this.formValue === null) return;
      let obj = {};
      if (this.mapValue === null) {
        obj = {
          ...this.formValue,
          graphList: null,
          longitude: this.pointForm.Lng,
          latitude: this.pointForm.Lat,
        };
      } else {
        obj = {
          ...this.formValue,
          graphList: this.mapValue,
          longitude: this.pointForm.Lng,
          latitude: this.pointForm.Lat,
        };
      }
      if (this.titles === "编辑网格" && this.formId) {
        obj.id = this.formId;
        obj.isDraw = this.retIds;
      }
      this.saveType = true;
      try {
        const res = await api.addGrid(obj);
        if (res.status === 200) {
          this.$message.success("操作成功");
          this.saveType = false;
          this.$refs.addform.resetForm();
          this.visibles = false;
          this.getList();
          this.pointForm = {};
        } else {
          this.$message.error("操作失败");
        }
      } catch (error) {
        console.error(error); // 处理错误
        this.saveType = false;
      }
    },
    retValue(e) {
      this.formValue = e;
    },
    formCancel() {
      this.mapValue = null;
      // this.mapValue = this.$refs.mapDraw.getMaparr();
      this.visibles = false;
      this.$refs.addform.resetForm();
      this.pointForm = {};
    },
    async supervisorList() {
      try {
        const res = await api.superVisorList();
        if (res.data) {
          let options = res.data.map((item) => {
            return {
              label: item.gridWorkerName,
              value: item.gridWorkerId,
            };
          });
          this.$nextTick(() => {
            // console.log(options);
            this.$refs.form.setFormItemProp("gridWorkerId", {
              options: {
                options,
                placeholder: "请选择网格负责人",
                showSearch: true,
                mode: "multiple",
                optionFilterProp: "label",
                labelInValue: false,
              },
            });
            // this.$refs.form.searchForm.setFormItemProp(
            //   "gridWorkerId",
            //   {
            //     options: {
            //       options,
            //       placeholder: "请选择网格负责人",
            //       showSearch: true,
            //       // optionFilterProp: "label",
            //       mode: "multiple",
            //       optionFilterProp: "label",
            //       labelInValue: false,
            //     },
            //   }
            // );
          });
        }
      } catch (error) {}
    },
    // 标点弹窗
    openPoint() {
      this.visiblePoint = true;
    },
    pointSave() {
      if (!this.$refs.mapPoint.retPoint().Lng) {
        this.$message.error("请添加标点");
        return;
      }
      this.visiblePoint = false;
      this.pointForm = this.$refs.mapPoint.retPoint();
      // 清空地图
      this.$refs.mapPoint.unloadMap();
      this.$refs.addform.getPoint(this.pointForm);
    },
    pointCancel() {
      this.visiblePoint = false;
      this.$refs.mapPoint.unloadMap();
    },
  },
};
</script>

<style scoped lang='less'>
.plotting {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 30%;
    height: 100%;
    border-radius: 5px;
  }
}
.actions {
  display: flex;
  justify-content: space-evenly;
  .edit {
    color: #2A5CFF;
    cursor: pointer;
  }
  .delete {
    color: #f95a5a;
    cursor: pointer;
  }
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #f5f7fb;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
/deep/ .ant-table-bordered .ant-table-fixed-right table {
	border-left: none;
}
</style>
